<template>
  <v-btn
      :text="true"
      :icon="true"
      :color="color"
      :large="large"
      :x-large="xlarge"
      :small="small"
      :x-small="xsmall"
      :class="iconBtnClass"
      @click="$emit('click')"
  >
    <v-icon :size="size"> {{ iconName }} </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    iconBtnClass: {
      type: String,
      require: false,
    },
    color: {
      type: String,
      require: false,
    },
    size: {
      type: String,
      require: false,
    },
    iconName: {
      type: String,
      require: true,
    },
    xsmall: {
      type: Boolean,
      require: false,
    },
    small: {
      type: Boolean,
      require: false,
    },
    large: {
      type: Boolean,
      require: false,
    },
    xlarge: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
