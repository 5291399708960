<template>
  <div>
    <div class="text-h6 my-3">{{ title }}</div>
    <v-row v-if="sideInputs && !sideLabels" no-gutters>
      <v-col cols="12" md="6" class="px-1">
        <v-text-field
          class="text-field"
          dense
          flat
          outlined
          v-model="range[0]"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="px-1">
        <v-text-field
          class="text-field"
          dense
          flat
          outlined
          v-model="range[1]"
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-else-if="!sideInputs && sideLabels" justify="space-between">
      <v-col>{{
        Math.ceil((range[0] * multiple) / devider) + " " + sideLabelsText
      }}</v-col>
      <v-col class="text-right">{{
        Math.ceil((range[1] * multiple) / devider) + " " + sideLabelsText
      }}</v-col>
    </v-row>

    <v-range-slider
      :value="range"
      @input="postpone"
      color="orange"
      :max="max"
      :min="min"
    ></v-range-slider>
  </div>
</template>

<script>
export default {
  name: "RangeSlideFilter",
  props: {
    /**
     * Отображаемая строка
     */
    title: {
      type: String,
      require: true,
    },
    /**
     * Поля ввода по бокам
     */
    sideInputs: {
      type: Boolean,
      default: false,
    },
    /**
     * Неизменяемые значения по бокам
     */
    sideLabels: {
      type: Boolean,
      default: false,
    },
    /**
     * Текст после неизменяемых значений
     */
    sideLabelsText: {
      type: String,
      default: "",
    },
    /**
     * Максимальное значение фильтра
     */
    max: {
      type: [String, Number],
      default: 100000,
    },
    /**
     * Минимальное значение фильтра
     */
    min: {
      type: [String, Number],
      default: 0,
    },
    /**
     * Множитель отображаемого значения
     */
    multiple: {
      type: Number,
      default: 1,
    },
    /**
     * Делитель отображаемого значения
     */
    devider: {
      type: Number,
      default: 1,
    },
  },
  model: {
    prop: "range",
    event: "update-range",
  },
  mounted() {
    this.range = [this.min, this.max];
  },
  data() {
    return {
      timerFilter: null,
      range: [],
    };
  },
  methods: {
    postpone(val) {
      clearTimeout(this.timerFilter);
      this.timerFilter = setTimeout(() => {
        this.range = val;
        this.$emit("update-range", val);
      }, 100);
    },
  },
};
</script>

<style>
.text-field {
  max-width: 100px;
}
</style>
