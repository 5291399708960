<template>
  <v-col align="center" cols="auto">
    <v-row align-content="center" align="center" justify="center">
      <slot></slot>
    </v-row>
    <v-row align-content="center" align="center" justify="center" class="mt-5 pa-2">
      <!-- <v-btn
        :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-h4'"
        @click="changeDay(previousDay.date)"
        color="orange"
        :disabled="previousDay.disabled"
        class="mx-5"
        >{{
          this.$vuetify.breakpoint.smAndUp ? previousDay.date : "&#8676;"
        }}</v-btn
      > -->
      <div
        :class="$vuetify.breakpoint.smAndUp ? 'text-h4' : 'text-h6'"
        class=" font-weight-bold"
      >
        {{ date }}
      </div>
      <!-- <v-btn
        :class="$vuetify.breakpoint.smAndUp ? 'text-h6' : 'text-h4'"
        @click="changeDay(nextDay)"
        color="orange"
        class="mx-5"
        >{{ this.$vuetify.breakpoint.smAndUp ? nextDay : "&#8677;" }}</v-btn
      > -->
    </v-row>
  </v-col>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";

export default {
  mixins: [DateFormat],
  props: {
    date: {
      type: String,
      require: true,
    },
    min: {
      type: Date,
      default: function() {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
      },
    },
  },
  computed: {
    previousDay() {
      let previous = this.addDays(
        new Date(this.formatDateToDatepicker(this.date)),
        -1
      );
      previous.setHours(0, 0, 0, 0);
      return {
        date: this.ddmmyyyy(previous, "."),
        disabled: previous < this.min,
      };
    },
    nextDay() {
      let date = new Date(this.formatDateToDatepicker(this.date));
      date = this.addDays(date, 1);
      return this.ddmmyyyy(date, ".");
    },
  },
  methods: {
    async changeDay(date) {
      this.$emit("update:day", date);
    },
  },
};
</script>

<style></style>
