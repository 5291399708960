<template>
  <v-col>
    <div v-for="(segment, i) in arrayOfSegments" :key="i">
      <flight-route
          :value="segment"
          :dateFormat="dateFormat"
          :timeFormat="timeFormat"
          :companiesData="companiesData"
          :airportsData="airportsData">
      </flight-route>

    </div>
  </v-col>
</template>
<script>
import FlightRoute from "@/components/FoundedTickets/FlightRoute.vue";
import {formatDistanceStrict} from "date-fns";

export default {
  props: {
    value: {
      type: Object,
      require: true,
    },
    companiesData: {
      type: Array,
    },
    dateFormat: {
      type: String,
    },
    timeFormat: {
      type: String,
    },
    areTransfersShow: {
      type: Boolean,
    },
    airportsData: {
      type: Map,
    },
  },
  components: {
    FlightRoute,
  },
  data() {
    return {
      showDetails: false,
      animation: false,
    };
  },
  methods: {
    stopsTitle(num) {
      num = Number(num);
      return num % 100 > 10 && num % 100 < 20
          ? "пересадок"
          : num % 10 == 1
              ? "пересадка"
              : num % 10 > 1 && num % 10 < 5
                  ? "пересадки"
                  : "пересадок";
    },
    //функция получает количество минут и возвращает время формата hh:mm,
    MinsToHoursAndMins(mins) {
      //преобразуем строку в число
      mins = parseInt(mins);
      //вычислим целое кол-во часов в минутах
      let hoursAnswer = Math.floor(mins / 60);
      //вычислим оставшиеся минуты
      let minsAnswer = mins % 60;
      //добавим 0, если это необходимо
      if (hoursAnswer < 10) {
        hoursAnswer = "0" + hoursAnswer;
      }
      if (minsAnswer < 10) {
        minsAnswer = "0" + minsAnswer;
      }
      return hoursAnswer + ":" + minsAnswer;
    },
    FormatDistanceStrictMethod(date1, date2, options) {
      return formatDistanceStrict(date1, date2, options);
    },
  },
  computed: {
    flightTime() {
      return (this.MinsToHoursAndMins(
          this.FormatDistanceStrictMethod(
              new Date(this.value.segments[0].departure.date),
              new Date(this.value.segments[this.value.segments.length - 1].arrival.date),
              {unit: "minute"}
          )
      ))
    },
    arrayOfSegments() {
      if (this.areTransfersShow) {
        return this.value.segments;
      } else {
        return [{
          arrival: this.value.segments[this.value.segments.length - 1].arrival,
          carrier: this.value.segments[0].carrier,
          departure: this.value.segments[0].departure,
          travelTime: this.flightTime,
        }];
      }
    }
  }
};
</script>

<style></style>
