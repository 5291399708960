<template>
  <v-row :id="id">
    <v-col>
      <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 4 : 1" class="px-5 pb-2 my-5" :class="colorIndex % 2 === 0 ? 'item1' : 'item2'">
          <v-chip v-if="$vuetify.breakpoint.mdAndUp" class="on-border-top" text-color="white"
            :color='transfersCount > 0 ? "orange" : "green"'>
            <span v-if="transfersCount > 0">
              {{ transfersInfo }}
            </span>
            <span v-else>
              Прямой
            </span>
          </v-chip>
          <v-row>
            <v-col cols="12" md="10">
              <v-row no-gutters>
                <v-col cols="12" class="text-center text-md-left d-flex">
                  <v-img max-height="30" max-width="30" :src=companyImgUrl.imgUrl></v-img>
                  <span class="text-h6 pl-2">
                    {{ carrierName }}
                  </span>
                </v-col>
              </v-row>
              <div v-for="(flight, i) in value.routes" :key="i">
                <v-fade-transition>
                  <v-row v-show="animation">
                    <flight-direction class="mb-5" :value="flight" :pult="value.Pult" :transfers="hasStops"
                      :companiesData="companiesData" :airportsData="airportsData" :dateFormat="dateFormat"
                      :timeFormat="timeFormat" :areTransfersShow="!showDetails && hasStops"></flight-direction>
                  </v-row>
                </v-fade-transition>
                <v-divider v-if="$vuetify.breakpoint.xs" class="my-0"></v-divider>
              </div>
              <v-row no-gutters class="mt-1">
                <div v-if="$vuetify.breakpoint.xs"></div>
                <v-chip v-else class="ml-2" color="orange" text-color="white">
                  Багаж: {{ value.baggage }}
                </v-chip>
              </v-row>
            </v-col>
            <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2"></v-divider>
            <v-col md="2" class="pr-md-0 pt-sm-3 pt-0">
              <v-row>
                <v-col v-if="$vuetify.breakpoint.xs" cols="2" class="text-left" align-self="center">
                  <v-btn v-if="hasStops" elevation="0" color="primary" small fab outlined @click="toggleDetails">
                    <v-icon v-if="showDetails">la-angle-down</v-icon>
                    <v-icon v-else>la-angle-up</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-2">
                  <v-row v-if="$vuetify.breakpoint.xs" class="">

                    <v-col cols="12" class="justify-space-between d-flex">
                      <v-chip style="height: 36px " label dark elevation="0" color="orange" class="">
                        Багаж: {{ value.baggage }}
                      </v-chip>
                      <v-btn dark elevation="0" color="orange" @click="$emit('Buy', value)">
                        Выбрать
                      </v-btn>
                    </v-col>
                    <v-row style="margin-top: 90px">
                      <v-col cols="12">
                        <v-btn :width='$vuetify.breakpoint.smAndUp ? "100%" : ""' elevation="0"
                          @click="getRules">Правила</v-btn>
                      </v-col>
                    </v-row>


                    <v-col class="pt-0 d-flex justify-center">
                      <div class="text-h5 font-weight-bold"
                        :class='ifCheapGreen ? "green--text" : (ifNormalOrange ? "orange--text" : "red--text")'>
                        {{ value.price.total }} РУБ
                      </div>
                    </v-col>

                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="pt-0 pb-1 pr-6">
                      <v-btn :width='$vuetify.breakpoint.smAndUp ? "100%" : ""' dark elevation="0" color="orange"
                        @click="$emit('Buy', value)">
                        Выбрать
                      </v-btn>
                    </v-col>
                    <v-col class="py-2 pr-6" cols="4" md="12">
                      <div class="text-h5 d-inline-block font-weight-bold"
                        :class='ifCheapGreen ? "green--text" : (ifNormalOrange ? "orange--text" : "red--text")'>
                        {{ value.price.total }} РУБ
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: 90px">
                    <v-col cols="12">
                      <v-btn :width='$vuetify.breakpoint.smAndUp ? "100%" : ""' elevation="0"
                        @click="getRules">Правила</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <icon-button v-if="$vuetify.breakpoint.smAndUp && hasStops" size="33" :small="true" color="primary"
              class="on-border-bottom" :iconName='showDetails ? "mdi-chevron-down-circle" : "mdi-chevron-up-circle"'
              @click="toggleDetails"></icon-button>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>
    <fare-remarks-modal v-model="fareRemarkModal" :text="fareRemarkText" :loading="fareRemarkLoading">
    </fare-remarks-modal>
  </v-row>
</template>

<script>
import FlightDirection from "@/components/FoundedTickets/FlightDirection.vue";
import IconButton from "../UI/IconButton.vue";
import FareRemarksModal from "@/components/FoundedTickets/FareRemarksModal.vue";

export default {
  name: "TicketsListItem",
  props: {
    value: {
      type: Object,
      require: true,
    },
    passengers: {
      type: Array,
      require: true,
    },
    colorIndex: {
      type: Number,
      default: 0,
    },
    maxPrice: {
      type: Number,
    },
    minPrice: {
      type: Number,
    },
    companiesData: {
      type: Array,
    },
    airportsData: {
      type: Map,
    },
  },
  components: {
    FlightDirection,
    IconButton,
    FareRemarksModal
  },
  data() {
    return {
      showDetails: false,
      animation: true,
      id: null,
      fareRemarkModal: false,
      fareRemarkLoading: false,
      fareRemarkText: null,
      dateFormat: "yyyy-MM-dd",
      timeFormat: "HH:mm",
    };
  },
  mounted() {

  },
  methods: {
    async getRules() {
      const endpoint = "/Order/FareRules";
      if (this.fareRemarkText) {
        this.fareRemarkModal = true;
        return;
      }
      var req = {
        id: this.value.id,
        Provider: this.value.provider
      }
      const resp = await this.$axios.post(endpoint, req)
      if (resp.status === 200) {
        this.fareRemarkText = resp.data
        this.fareRemarkModal = true;
      }
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
      this.animation = false;
      setTimeout(() => {
        this.animation = true;
        this.$vuetify.goTo("#" + this.id);
      }, 100);
    },
    stopsTitle(num) {
      num = Number(num);
      return num % 100 > 10 && num % 100 < 20
        ? "пересадок"
        : num % 10 === 1
          ? "пересадка"
          : num % 10 > 1 && num % 10 < 5
            ? "пересадки"
            : "пересадок";
    },
  },
  computed: {
    luggageInfo(type) {
      type = this.value.baggage
      switch (type) {
        case "No Info":
          return "Нет информации о багаже"
        case "Free":
          return "Бесплатный багаж"
        case "CarryOn":
          return "Ручная кладь"
        case "Checked":
          return "Зарегистрированный багаж"
        default:
          return "Нет информации о багаже"
      }
    },
    companyImgUrl() {
      let companyData = this.companiesData.find(company => company.code === (this.value.routes[0].segments[0].carrier));
      return companyData === undefined ? { imgUrl: "" } : companyData;
    },
    carrierName() {
      let companyName = this.companiesData.find(company => company.code === (this.value.routes[0].segments[0].carrier));
      return companyName === undefined ? this.value.routes[0].segments[0].carrier : companyName.name;
    },
    transfersCount() {
      let transfersCount = 0;
      for (let i = 0; i < this.value.routes.length; i++) {
        transfersCount += this.value.routes[i].segments.length - 1;
      }
      return transfersCount;
    },
    hasStops() {
      for (let i = 0; i < this.value.routes.length; i++) {
        if (this.value.routes[i].segments.length > 1) {
          return true;
        }
      }
      return false;
    },
    transfersInfo() {
      return this.transfersCount + " " + this.stopsTitle(this.transfersCount);
    },
    ifCheapGreen() {
      return this.value.price.total < ((this.maxPrice - this.minPrice) * 0.25 + (this.minPrice)) || this.value.price.total < this.minPrice * 1.25;
    },
    ifNormalOrange() {
      return this.value.price.total < ((this.maxPrice - this.minPrice) * 0.55 + (this.minPrice)) || this.value.price.total < this.minPrice * 1.55;
    }
  },
  filters: {},
};
</script>

<style scoped>
.price {
  display: inline-block;
  font-weight: bold;
}

.on-border-top {
  top: -17px;
  right: -10px;
}
</style>
