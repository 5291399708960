export default class Passenger {
  Id = "";
  Birthday = null;
  GenderCode = ""; // Пол
  TypeCode = ""; //(взрослый, ребенок, младенец )
  Surname = "";
  FirstName = "";
  MiddleName = "";
  DocNumber = ""; //номер документа
  TypeCodeDoc = ""; //что за документ
  IssueCountryCode = ""; // код страны
  IssueDate = null; //дата выдачи
  ExpiryDate = null; //дата окончания паспорта

  constructor(options) {
    let test = options.test || false;
    if (test) {
      this.Id = "Pax-1";
      this.Birthday = new Date();
      this.GenderCode = "M";
      this.TypeCode = options.Category;
      this.Surname = "Nikitin";
      this.FirstName = "Maksim";
      this.MiddleName = "Sergeevich";
      this.DocNumber = "4511555222";
      this.TypeCodeDoc = "PS";
      this.IssueCountryCode = "RU";
      this.IssueDate = new Date();
    } else {
      this.Id = "";
      this.Birthday = null;
      this.GenderCode = "M";
      this.TypeCode = options.Category;
      this.Surname = "";
      this.FirstName = "";
      this.MiddleName = "";
      this.DocNumber = "";
      this.TypeCodeDoc = "PS";
      this.IssueCountryCode = "RU";
      this.IssueDate = null;
    }
  }

  Validate() {
    return (
        this.Name &&
        this.Surname &&
        this.Dob &&
        this.Birthdate &&
        this.Sex &&
        this.Category &&
        this.Doccode &&
        this.Doc &&
        this.DocCountry &&
        this.Nationality &&
        this.Residence
    );
  }
}
