export default {
  methods: {
    async getInsurances(company, flight, pult) {
      try {
        var resp = await this.$axios.get(
            `/Insurance/GetInsurances?company=${company}&flight=${flight}&pult=${pult}`
        )
        console.log(resp.data)
        return resp.data
      } catch (e) {
        console.log('getInsurances - Error')
        throw e
      }
    },
    async getRecieptInfo(regnum, surname, pult) {
      try {
        var resp = await this.$axios.get(
            `/ReceiptData?regnum=${regnum}&surname=${surname}&pult=${pult}`
        )
        console.log(resp.data)
        return resp.data
      } catch (e) {
        console.log('getRecieptInfo - Error')
        throw e
      }
    },
    async getTickets() {
      try {
        let url = `/FindTkt?air_class=${this.cls}&date=${this.date}&dateBack=${this.back}&fromCity=${this.src}&toCity=${this.dest}&adults=${this.adults}&kids=${this.kids}&babies=${this.bbs}&direct=${this.direct}`
        let resp = await this.$axios.get(url)
        return resp.data
      } catch (e) {
        console.log('getTickets - Error')
        //this.hasErrors = true;
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        throw e
      }
    },
    
    async checkOption(tariff = null) {
      let data = {
        Query: {
          PricingVariant: {
            Segments: JSON.parse(this.segments),
            Passengers: this.getPassengersData(
                this.adults,
                this.kids,
                this.bbs
            ),
          },
          Pult: this.pult,
          Brand: tariff ? tariff.RequestedBrands : null,
        },
      }
      if (tariff !== null)
        data.Query.PricingVariant.Segments.forEach(
            (e) => (e.Subclass = tariff.Subclass)
        )
      console.log('checkOption')
      console.log(data)
      try {
        let resp = await this.$axios.post(`/CheckFlight`, data)
        console.log(resp.data.Answer.PricingVariant.Variant)
        return resp.data.Answer
      } catch (e) {
        console.log('checkOption - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        return
      }
    },
    async checkBrandTariffs(segments, passangers, pult) {
      let data = {
        Query: {
          PricingMonoBrand: {
            Segments: segments,
            Passengers: passangers,
          },
          Pult: pult,
        },
      }
      try {
        let resp = await this.$axios.post(`/checkBrandTariffs`, data)
        return resp.data
      } catch (e) {
        console.log('checkBrandTariffs - Error')
        throw e
      }
    },
    // async proceedToBooking() {
    //   let req = {
    //     Query: {
    //       Pult: this.pult,
    //       Booking: {
    //         Segments: JSON.parse(this.segments),
    //         Passengers: this.passengers,
    //         Customer: {
    //           Phone: {
    //             Type: "mobile",
    //             Comment: "no comment",
    //             Text: this.phone,
    //           },
    //           Email: this.email,
    //         },
    //       },
    //     },
    //   };
    //   console.log("Бронь");
    //   console.log(req);
    //   this.bookLoading = true;
    //   try {
    //     let resp = await this.$axios.post(`/Book`, req);
    //     this.order = resp.data.Answer.Order;
    //     this.regnum = this.order.Pnr.Regnum;
    //     this.surname = this.order.Pnr.Passengers.Passenger[0].Surname;
    //     this.setServices();
    //     this.bookLoading = false;
    //     this.ASDialog = true;
    //   } catch (e) {
    //     console.log("Error - proceedToBooking");
    //     this.hasErrors = true;
    //     this.error = {
    //       Code: e.response.status,
    //       Message: e.response.data.Message,
    //     };
    //     this.bookLoading = false;
    //     return;
    //   }
    // },

    async proceedToBooking(
        segments,
        passengers,
        phone,
        email,
        brand,
        pult
    ) {
      let req = {
        Query: {
          Pult: pult,
          Booking: {
            Segments: segments,
            Passengers: passengers,
            Customer: {
              Phone: {
                Type: 'mobile',
                Comment: 'no comment',
                Text: phone,
              },
              Email: email,
            },
            RequestParams: {
              Brands: [{ Text: brand }],
            },
          },
        },
      }
      console.log('Бронь')
      console.log(req)
      this.bookLoading = true
      try {
        let resp = await this.$axios.post(`/Book`, req)
        this.order = resp.data.Answer.Order
        this.regnum = this.order.Pnr.Regnum
        this.surname = this.order.Pnr.Passengers.Passenger[0].Surname
        this.setServices()
        //this.ASDialog = true;
      } catch (e) {
        console.log('Error - proceedToBooking')
        throw e
      }
    },
    async proceedToPayment() {
      console.log('proceed to payment')
      this.payLoading = true

      try {
        if (this.selectedServicesCount) await this.addASToPnr()
        else this.book = this.order
        this.payDialog = true
      } catch (e) {
        this.hasErrors = true
        this.error = this.error = {
          Message: e.message,
        }
        this.payDialog = false
      } finally {
        this.payLoading = false
      }
    },
    async getDocs() {
      try {
        let resp = await this.$axios.get(`/DocTypes`)
        return resp.data
      } catch (e) {
        console.log('getDocs - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        return []
      }
    },

    async getOrder() {
      try {
        let resp = await this.$axios.get(
            `/order?regnum=` +
            this.regnum +
            '&surname=' +
            this.surname +
            '&pult=' +
            this.pult
        )
        this.order = resp.data.Answer.Order
        this.cash = this.totalPaymentAmount
        this.maxPayment = this.totalPaymentAmount
      } catch (e) {
        console.log('getOrder - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        return
      }
    },
    async CheckPrintedInfo() {
      try {
        let resp = await this.$axios.get(
            `/CheckPrinted?regnum=` +
            this.regnum +
            '&surname=' +
            this.surname
        )
        this.checkPrinted = resp.data.CheckPrinted
      } catch (e) {
        console.log('getOrder - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        return
      }
    },
    async SendTicketToPassenger() {
      let req = {
        query: {
          Pult: this.pult,
          itinReceipt: {
            regnum: this.regnum,
            surname: this.surname,
          },
        },
      }

      try {
        console.log('Start send email')
        let resp = await this.$axios.post(`/Receipt/SendTicket`, req)
        this.pdfByBase64 = resp.data
        console.log('End send email')
      } catch (e) {
        console.log('SendTicketToPassenger - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        return
      }
    },

    async GetPDF(pult, regnum, surname) {
      let req = {
        query: {
          Pult: pult,
          itinReceipt: {
            regnum: regnum,
            surname: surname,
          },
        },
      }
      console.log(req)
      try {
        let resp = await this.$axios.post(`/Receipt/GetPDF`, req)
        console.log(resp)
        return resp.data
      } catch (e) {
        console.log('GetPDF - Error')
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        }
        throw e
      }
    },
  },
}
